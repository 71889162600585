html,
html[data-theme='PPAYA'] {
  --color-ppa-sidebarBackground: #00224e;
  --color-ppa-sidebarButtonBackground: #334e71;
  --color-ppa-sidebarButtonText: #99a7b8;
  --color-ppa-sidebarButtonTextActive: #ffffff;
  --color-ppa-sidebarButtonSubmenuTextActive: #ffffff;
  --color-ppa-sidebarButtonIcon: #99a7b8;
  --color-ppa-sidebarButtonIconActive: #00faca;

  --color-ppa-aiAssistantMessageBg: #ccd3dc33;

  --color-ppa-secondaryButtonBackground: #ffffff;
  --color-ppa-quaternaryButtonBackground: #eeeeee;

  --color-ppa-sidebarProfileName: #ccd3dc;
  --color-ppa-sidebarProfileEmail: #99a7b8;
  --color-ppa-sidebarProfileBackground: #334e71;
  --color-ppa-sidebarProfileIcon: #99a7b8;

  --color-ppa-sidebarButtonBackground: #334e71;

  --color-ppa-primary: #005dd5;
  --color-ppa-primaryFade: #626b6b1a;
  --color-ppa-primarySelected: #005dd5;
  --color-ppa-primaryActive: #00faca;

  --color-ppa-secondary: #4c5172;
  --color-ppa-secondaryFade: #626b6b1a;
  --color-ppa-secondarySelected: #7782c6;
  --color-ppa-secondaryActive: #a4aace33;

  --color-ppa-title: #334e71;
  --color-ppa-subtitle: #334e71;
  --color-ppa-quoteSubtitle: #667a95;
  --color-ppa-formStar: #00faca;
  --color-ppa-formText: #334e71;
  --color-ppa-warning: #0090ff;
  --color-ppa-filterBorder: #e6e8ec;
  --color-ppa-filterBorderActive: #3385ff;
  --color-ppa-senaryButtonBg: #cce9ff;
  --color-ppa-quaternaryButtonText: #6b7280;

  --color-ppa-tableTitleText: #334e71;
  --color-ppa-tableTitleBackground: #f7fafd;
  --color-ppa-tableRowHover: #f2fefc;
  --color-ppa-tableRowText: #334e71;
  --color-ppa-tableRowHighlight: #356fd7;
  --color-ppa-tableRowSelected: #ebf4ff;
}
